.user-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-upload-list-item{
    border: none !important;
    img{
      object-fit: cover !important;
    }
  }
  .ant-avatar {
    width: var(--size-900);
    height: var(--size-900);
    margin-bottom: var(--size-400);
  }
  .name {
    font-weight: var(--fw-bold);
  }
}
.students-list::-webkit-scrollbar {
  width: 5px;
}

.students-list::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.students-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #363636;
}
// .students-list::-webkit-scrollbar{
//   display: none !important;
// }

.main-data {
  .item {
    margin-bottom: var(--size-500);
    .label {
      font-style: normal;
      line-height: normal;
      color: var(--clr-neutral-800);
      font-size: var(--fs-400);
      font-weight: var(--fw-regular);
      margin-bottom: var(--size-200);
    }

    .value {
      font-style: normal;
      line-height: normal;
      color: var(--clr-primary-100);
      font-size: var(--fs-600);
      font-weight: var(--fw-semi-bold);
    }
  }
}

.btn-space {
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
  button {
    width: 100%;
  }
}

.applied-universities-tab {
  margin-top: var(--size-700) !important;
  .ant-tabs-extra-content {
    flex-grow: 1;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: none !important;
    }
  }
}

.add-profile-tabs {
  margin-bottom: var(--size-500);
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    padding: var(--size-500) 0;
    padding-right: var(--size-500);
  }

  .ant-tabs-nav {
    height: 100%;

    .ant-tabs-tab {
      margin-top: 0 !important;
      margin: 0 !important;
      padding: var(--size-400) var(--size-500) !important;
      border-bottom: 1px solid var(--clr-neutral-300);
      .ant-tabs-tab-btn {
        color: #081233;
        font-weight: 500;
      }
    }
  }
}
