.only-view * {
   pointer-events: none;
}
.anti-only-view{
   pointer-events: all;
}
.ant-upload-list-item a{
   pointer-events: all;
   cursor: pointer;
}
.file-upload .ant-form-item{
margin-bottom: 0;
}

.univ_story .ant-upload-list-item-actions{
   inset-inline-start: 50% !important
}

.image-msg *{
   padding: 0 !important;
}