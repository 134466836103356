:root {
  --clr-accent-500: hsl(349, 94%, 55%);
  --clr-accent-400: hsl(357, 100%, 65%);
  --clr-accent-300: hsl(12, 88%, 75%);
  --clr-accent-100: hsl(13, 100%, 96%);

  --clr-primary-400: hsl(226, 72%, 6%);
  --clr-primary-100: hsl(0, 0%, 0%);

  --clr-neutral-900: hsl(0, 0%, 46%);
  --clr-neutral-800: hsl(0, 0%, 58%);
  --clr-neutral-700: hsl(0, 24%, 88%);
  --clr-neutral-600: hsl(0, 0%, 77%);
  --clr-neutral-300: hsl(0, 0%, 82%);
  --clr-neutral-200: hsl(0 0% 97%);
  --clr-neutral-100: hsl(0 0% 100%);

  --ff-primary: "Inter", sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 600;

  --fs-300: 0.8125rem; /*13px*/
  --fs-400: 0.875rem; /*14px*/
  --fs-500: 0.9375rem; /*15px*/
  --fs-600: 1rem; /*16px*/
  --fs-650: 1.375rem; /*22px*/
  --fs-700: 1.625rem; /*26px*/
  --fs-800: 2rem; /*32px*/
  --fs-900: 3rem; /*48px*/

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-700);
  --fs-secondary-heading: var(--fs-650);
  --fs-button: var(--fs-400);

  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;
}

@media (min-width: 50em) {
  :root {
    --fs-body: var(--fs-500);
    --fs-primary-heading: var(--fs-900);
    --fs-secondary-heading: var(--fs-800);
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  font-family: var(--ff-body) !important;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"],
li {
  list-style: none;
}

a {
  text-decoration: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

.swiper {
  text-rendering: optimizeLegibility;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* general styling */

body {
  font-size: var(--fs-body);
  font-family: var(--ff-body) !important;
  color: var(--clr-primary-100);
}

p:not([class]) {
  opacity: 0.7;
}

h1 {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
  line-height: var(--size-600);
  color: var(--clr-primary-100);
}

h2 {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  line-height: var(--size-500);
  color: var(--clr-primary-100);
}
h3 {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  line-height: var(--size-500);
  color: var(--clr-primary-100);
}
/* p {
  font-size: var(--fs-400);
  font-weight: var(--fw-regular);
  line-height: var(--size-500);
  color: var(--clr-primary-100);
} */

.clickable {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all ease 100ms;
}

.clickable:active {
  transform: translateY(2px);
}
.review-stat .ant-statistic-content{
 font-size: 17px !important;
}
