.application-sider {
  // padding-right: var(--size-500);
  border-right: 1px solid var(--clr-neutral-300);
}
.studen-list-wrappers {
  .ant-card-body {
    padding-left: 0 !important;
  }
}
.student-profile-wrapper {
  .ant-card-body {
    padding-left: 20px !important;
  }
}
.gap-2 {
  gap: 12px;
}
.applicant-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: var(--size-200);
  border-radius: var(--size-200);
  transition: all ease 500ms;
  border: 1px solid transparent;
  border-radius: 0 !important;
  padding: 20px;
  svg {
    display: none !important;
  }

  &.active {
    background-color: #f5f5f5;
  }
  &:hover {
    background-color: #f5f5f5;
    svg {
      display: block !important;
    }
  }
  .ant-card-meta-title {
    margin-bottom: 0 !important;
  }
}
