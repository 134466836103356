.add-university {
  border: 1px solid var(--clr-neutral-300);
  border-radius: var(--size-200);
  overflow: hidden;
  margin-bottom: var(--size-500);

  .ant-tabs-content-holder {
    padding: var(--size-500) 0;
    padding-right: var(--size-500);
  }
}
.social-media-tabs {
  .ant-tabs-nav {
    height: max-content !important;
  }
  .ant-tabs-tab {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
    .ant-tabs-tab-btn {
      font-weight: var(--fw-semi-bold);
    }
  }
}
.add-university-tabs {
  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-bottom: 1px solid var(--clr-neutral-300);
      .ant-tabs-tab-btn {
        font-weight: var(--fw-semi-bold);
      }
    }
  }
}

.major-subjects {
  .ant-tag {
    cursor: pointer;
    font-size: var(--fs-400);
    padding: var(--size-100) var(--size-300) !important;
    &:hover {
      border-color: #91caff;
    }
  }
}

.review-card {
  width: 100%;
  .ant-card-meta-title {
    margin-bottom: 0 !important;
  }
}
