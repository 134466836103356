.cusom-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: var(--size-600);
  height: var(--size-900);

  .content {
    gap: var(--size-700);
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--size-400);

      a {
        color: var(--clr-neutral-100);
        font-size: var(--fs-600);
      }

      svg {
        color: var(--clr-neutral-100);
        font-size: 40px;
      }
    }

    .user-box {
      display: flex;
      align-items: center;
      gap: var(--size-400);

      .data-box {
        line-height: 100%;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .username {
          font-size: var(--fs-600);
        }

        .position {
          font-size: var(--fs-400);
        }
      }

      .ant-avatar {
        width: 48px;
        height: 48px;
        background: var(--clr-accent-100);
      }
    }
  }
}

.ant-layout {
  min-height: 100dvh;
}

.ant-layout-header {
  top: 0;
  position: sticky;
  z-index: 98;
  background: #040919 !important;
}

.logo-vertical {
  position: relative;
  width: 100%;
  padding: var(--size-400) var(--size-500);
  background: #040919;
  img {
    object-fit: contain;
    object-position: left;
  }
}

.ant-layout {
  padding-bottom: 0;
  border-bottom: none;
}

.ant-layout-sider {
  flex: 0 0 16rem !important;
  max-width: 16rem !important;
  min-width: 16rem !important;
  width: 16rem !important;
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100dvh;
  overflow: hidden !important;
  z-index: 99;
}

.ant-layout-content {
  padding: var(--size-500);
  padding-bottom: 0;
  overflow: initial !important;
  margin-left: 16rem;
  background: var(--clr-neutral-100);
  min-height: calc(100dvh - 70px) !important;
}

.ant-breadcrumb {
  margin-bottom: var(--size-500) !important;
}

.justify-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.align-end {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.react-tel-input {
  font-size: var(--fs-600) !important;
  .form-control {
    border: none !important;
    outline: none;
    width: 100% !important;
    height: 100% !important;
  }
  .flag-dropdown {
    border: none !important;
    background: none !important;
  }
}

.ck-content {
  height: 250px;
  p {
    color: var(--clr-primary-100) !important;
    opacity: 1 !important;
  }
}
.ant-badge {
  margin-right: 0.5rem !important;
}
.dot {
  display: block;
  width: 9px;
  height: 9px;
  flex-shrink: 0;
  border-radius: 50%;
  background: var(--clr-primary-100);
}

.review-detail {
  .ant-space {
    width: 100% !important;
    margin-bottom: var(--size-400);
  }
}
.search-space {
  display: flex;
  justify-content: space-between;
  &:first-child {
    flex-grow: 1;
  }
  .ant-space-item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.choose-btn {
  background: #f4f4f4;
  outline: none !important;
  max-width: max-content !important;
  span {
    &:nth-child(2) {
      padding-right: var(--size-400);
      font-size: var(--fs-400);
    }
  }
}
.order-tabs {
  // .ant-tabs-ink-bar {
  //   background: transparent !important;
  // }
  .ant-tabs-nav::before {
    border: none !important;
  }
  // .ant-tabs-tab {
  //   padding: 13px 40px 12px;
  //   border-radius: 8px;
  //   font-weight: var(--fw-bold);
  //   margin-left: 0 !important;
  //   &.ant-tabs-tab-active {
  //     background: #e1ebff;
  //     .ant-tabs-tab-btn {
  //       color: var(--clr-primary-100);
  //     }
  //   }
  // }
}
button.ant-modal-close, button.ant-modal-close:hover{

  box-sizing: content-box !important;
  border-radius: 50%;
    padding: 6px 5px 4px 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ant-menu {
  background: #040919 !important;
  .ant-menu-item-group-title{
    color: #ffffff !important;
  }
  *{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .ant-menu-item {
    width: 100%;
    color: #ffffff !important;
    border-radius: 0;
    gap: var(--size-400) !important;
    .ant-menu-item-group-title, span>a{
      color: #ffffff !important;
    }
  }
  .ant-menu-item-selected {
    // background: var(--clr-neutral-100);
    background-color: white !important;
    color: #040919 !important;
    span>a{
      color: #040919 !important;
    }
  }
  .ant-menu-item-active {
    // background: var(--clr-neutral-100);
    background-color: white !important;
    color: #040919 !important;
    span>a{
      color: #040919 !important;
    }
  }
}

.ant-bandge {
  display: block !important;
  margin-right: 8px !important;
}

.ant-input-lg,
.ant-picker-large {
  height: 40px !important;
}

.ant-modal-close-x {
  width: 22px !important;
  height: 20px !important;
}

.avatar-status-name {
  font-size: var(--fs-600);
}
.ant-dropdown {
  .ant-checkbox {
    top: 0 !important;
  }
}

.ant-card-head {
  border-bottom: none !important;
}

.inquiries-tab {
  margin-bottom: var(--size-500);
  .ant-tabs-tab {
    width: 15rem;
  }
  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-content-holder {
    padding: var(--size-500) 0;
    padding-right: var(--size-500);
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-top: 0 !important;
      margin: 0 !important;
      padding: var(--size-400) var(--size-500) !important;
      border-bottom: 1px solid var(--clr-neutral-300);
      .ant-tabs-tab-btn {
        color: #081233;
        font-weight: 500;
      }
    }
  }
}

.why-eduance {
  .avatar-status-name {
    color: var(--clr-neutral-100);
  }
}
.map-wrapper {
  position: relative;
  width: 100%;
  height: 50dvh;
}

.custom-marker {
  width: max-content;
  height: max-content;
  object-fit: contain;
}

.address-input {
  background: transparent;
  border: 1px solid #e6ebf1;
  padding: 0 11px;
  border-radius: 0.625rem;
  color: #455560;
  height: 40px;
  width: 98%;
  outline: none !important;
}
.address-input:hover {
  border: 1px solid #3e79f7;
}
.address-input:focus {
  box-shadow: 0 0 0 2px rgba(62, 121, 247, 0.2) !important;
  border-color: #3e79f7 !important;
}

.student-avatar .ant-avatar-string{
  left:unset !important
}


