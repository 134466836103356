.login-wrapper {
  position: relative;
  display: flex;
  height: 100dvh;
  background: var(--clr-neutral-100);
  justify-content: center;
}

.auth-page-logo {
  position: absolute;
  top: var(--size-500);
  left: calc(var(--size-900) + 3rem);
  @media (max-width: 1200px) {
  left: 10%;
  }
}
.login-form {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: var(--size-500) calc(var(--size-900) + 3rem);

  .ant-form {
    width: 100%;
  }

  .title {
    width: 100%;
    color: #111827;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: var(--size-500);
  }
  button {
    margin-top: var(--size-500);
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 450px;
    padding: 20px;
  }
}

.admin-platform {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  background: #040919;
  padding: var(--size-500) calc(var(--size-900) + 3rem);

  .title {
    width: 100%;
    color: var(--clr-neutral-100);
    font-feature-settings: "clig" off, "liga" off;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    display: none;
  }
}
